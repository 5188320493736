import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <section className="flex center column">
      <h1 className="text-primary huge">404</h1>
      <h3 className="text-disabled"><stong>Oops!</stong> The page you were looking for could not be found.</h3>
    </section>
  </Layout>
)

export default NotFoundPage
